<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_estate_contacts") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateEstateContacts"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const estate = computed(() => store.state.estate.estate);
    const company = computed(() => store.state.company.company);
    const contacts = computed(() => store.state.user.contacts);
    const personnel = computed(() => store.state.user.personnel);
    const boardMembers = computed(() => store.state.user.boardMembers);
    const maintenancePersonnel = computed(
      () => store.state.user.maintenancePersonnel
    );

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const formElements = ref();

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "manager",
          label: "manager",
          optionLabel: ["firstName", "lastName"],
          options: personnel.value,
          rules: ["isRequired"],
          value: estate.value.managerId,
          variable: "managerId",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "chairman",
          label: "chairman",
          optionLabel: ["firstName", "lastName"],
          options: boardMembers.value,
          rules: ["isRequired"],
          value: estate.value.chairmanId,
          variable: "chairmanId",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "secretary",
          label: "secretary",
          optionLabel: ["firstName", "lastName"],
          options: personnel.value,
          rules: ["isRequired"],
          value: estate.value.secretaryId,
          variable: "secretaryId",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const chairman = computed(() => {
      return contacts.value.find(
        (contact) => contact.id == estate.value.chairmanId
      );
    });

    const manager = computed(() => {
      return contacts.value.find(
        (contact) => contact.id == estate.value.managerId
      );
    });

    const secretary = computed(() => {
      return contacts.value.find(
        (contact) => contact.id == estate.value.secretaryId
      );
    });

    onMounted(() => {
      if (!estate.value.id || estate.value.id != route.params.estateId) {
        store.dispatch("estate/getEstate", route.params.estateId);
      } else {
        const contactIds = [];
        if (estate.value.chairmanId) contactIds.push(estate.value.chairmanId);
        if (estate.value.managerId) contactIds.push(estate.value.managerId);
        if (estate.value.secretaryId) contactIds.push(estate.value.secretaryId);
        if (contactIds.length)
          store.dispatch("user/getEstateContacts", contactIds);
        store.dispatch("user/getPersonnel", estate.value.companyId);
        store.dispatch("user/getBoardMembers", estate.value.id);
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    watch(
      () => estate.value,
      (estate) => {
        if (estate.id) {
          const contactIds = [];
          if (estate.chairmanId) contactIds.push(estate.chairmanId);
          if (estate.managerId) contactIds.push(estate.managerId);
          if (estate.secretaryId) contactIds.push(estate.secretaryId);
          if (contactIds.length)
            store.dispatch("user/getEstateContacts", contactIds);
          store.dispatch("user/getPersonnel", estate.companyId);
          store.dispatch("user/getBoardMembers", estate.id);
        }
      }
    );

    watch(
      () => maintenancePersonnel.value,
      (maintenancePersonnel) => {
        if (maintenancePersonnel.length) {
          setFormElements();
        }
      }
    );

    watch(
      () => boardMembers.value,
      (boardMembers) => {
        if (boardMembers.length) {
          setFormElements();
        }
      }
    );

    return {
      personnel,
      boardMembers,
      chairman,
      maintenancePersonnel,
      manager,
      secretary,
      formElements,
      t,
      updateEstateContacts: (data) =>
        store.dispatch("estate/updateEstateContacts", {
          id: estate.value.id,
          data,
        }),
      waiting,
    };
  },
};
</script>
