<template>
  <div class="flex items-center text-sm font-bold">
    <skeleton v-if="waiting" class="h-6 w-full" />
    <div v-else class="flex items-center">
      <HomeIcon
        class="h-5 w-5 cursor-pointer"
        :style="{
          color: hovers[0]
            ? company.primary[600]
            : !items.length
            ? company.primary[500]
            : null,
        }"
        @click="router.push('/')"
        @mouseover="hovers[0] = true"
        @mouseleave="hovers[0] = false"
      />
      <div v-for="(item, index) in items" :key="item" class="flex items-center">
        <ChevronRightIcon class="mx-2 h-3 w-3" />
        <span
          class="cursor-pointer"
          :style="{
            color: hovers[index + 1]
              ? company.primary[600]
              : index == items.length - 1
              ? company.primary[500]
              : null,
          }"
          @click="router.push(item.to)"
          @mouseover="hovers[index + 1] = true"
          @mouseleave="hovers[index + 1] = false"
          >{{
            item.translatable ? t(`breadcrumb.${item.label}`) : item.label
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Skeleton from "../loading/Skeleton.vue";
export default {
  components: {
    ChevronRightIcon,
    HomeIcon,
    Skeleton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const hovers = ref([]);

    const items = ref([]);

    const company = computed(() => store.state.company.company);
    const document = computed(() => store.state.document.document);
    const estate = computed(() => store.state.estate.estate);
    const key = computed(() => store.state.key.key);
    const user = computed(() => store.state.user.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.document.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.key.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const setBreadcrumbs = () => {
      items.value = [];
      let label = "";
      let to = "";
      for (const item of route.meta.breadcrumb) {
        let translatable = false;
        if (item == "document") {
          label = document.value.name;
          to += `/${document.value.id}`;
        } else if (item == "estate") {
          label = estate.value.name;
          to += `/${estate.value.id}`;
        } else if (item == "user") {
          label = `${user.value.firstName} ${user.value.lastName}`;
          if (to.includes("estates")) {
            to += "/persons";
          }
          to += `/${user.value.id}`;
        } else if (item == "key") {
          label = key.value.stamp;
          if (to.includes("estates")) {
            to += "/keys";
          }
          to += `/${key.value.id}`;
        } else {
          label = item;
          if (
            !to.includes("estates") &&
            (item != "keys" || item != "persons")
          ) {
            to += `/${item}`;
          }
          translatable = true;
        }
        items.value.push({
          label,
          to,
          translatable,
        });
      }
    };

    onMounted(() => setBreadcrumbs());

    watch(
      () => estate.value,
      () => {
        setBreadcrumbs();
      }
    );

    watch(
      () => key.value,
      () => {
        setBreadcrumbs();
      }
    );

    watch(
      () => user.value,
      () => {
        setBreadcrumbs();
      }
    );

    return {
      company,
      hovers,
      items,
      router,
      t,
      waiting,
    };
  },
};
</script>
